import {
    GET_TREATMENT_DATA_REQUEST,
    GET_TREATMENT_DATA_SUCCESS,
    GET_TREATMENT_DATA_FAIL,
    UPDATE_TREATMENT_DATA_REQUEST,
    UPDATE_TREATMENT_DATA_SUCCESS,
    UPDATE_TREATMENT_DATA_FAIL,
    DELETE_TREATMENT_DATA_REQUEST,
    DELETE_TREATMENT_DATA_SUCCESS,
    DELETE_TREATMENT_DATA_FAIL,
    TREATMENT_DATA_CHANGE_REQUEST,
    ADDITIONAL_TREATMENT_DATA_CHANGE_REQUEST,
    RECOMMENDATION_DATA_CHANGE_REQUEST,
    OPEN_RECOMMENDATION_LOADING_SCREEN,
    RECOMMENDATION_LOADER_SCREEN_TIMING_STATE,
    GENERATE_RECOMMENDATION_REQUEST,
    GENERATE_RECOMMENDATION_REQUEST_SUCCESS,
    GENERATE_RECOMMENDATION_REQUEST_FAILURE,
    CHECKED_RECOMMENDATION_REQUEST,
    IS_EDIT_RECOMMENDATION,
    RECOMMENDATION_PREVIOUS_DATA,
    GET_RECOMMENDATION_DATA_REQUEST,
    GET_RECOMMENDATION_DATA_SUCCESS,
    GET_RECOMMENDATION_DATA_FAIL,
    UPDATE_RECOMMENDATION_DATA_REQUEST,
    HYGIENE_REGIME_DATA_CHANGE_REQUEST,
    FILTER_PROPOSED_DATA_REQUEST,
    ADDITIONAL_ODONTOGRAM_DATA_REQUEST,
    ADD_HYGIENE_REGIME,
    ADD_ADJUNCTIVE,
    SET_STAGING_GRADING_FROM_KEY_FINDING,
    UPDATE_STAGING_GRADING,
    UPDATE_EXTENT,
    ADD_NOTES_HYGIENE_REGIME,
    ADD_REFERRALS, UPDATE_X_RAY_CYCLE_REQUEST, UPDATE_X_RAY_CYCLE_REQUEST_SUCCESS, UPDATE_X_RAY_CYCLE_REQUEST_FAILURE
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/treatment/recommendationsTreatment';

const initialState: any = {
    getRecommendationLoader: false,
    pendingRecommendation: false,
    treatmentData: [],
    hygieneRegimeData: [],
    scriptData: null,
    insightRecommendationData: null,
    insightRecommendationDataPrevious: null,
    additionalTreatments: null,
    insightRecommendation: {
        category: null,
        insightRecommendationCheckedData: [],
        insightRecommendationCheckedId: []
    },
    insightRecommendationPrevious: {
        category: null,
        insightRecommendationCheckedData: [],
        insightRecommendationCheckedId: []
    },
    deleteRecommendationLoader: false,
    error: null,
    recommendationErrors: {},
    treatmentErrors: [],
    openRecommendationLoadingScreen: false,
    recommendationLoadingScreenTiming: false,
    generateRecommendationLoader: false,
    generateRecommendation: null,
    isEditRecommendation: null,
    getOraQProposedLoader: false,
    oraQProposedData: [],
    oraQProposedError: null,
    filterProposedData: [],
    additionalOdontogramData: {},
    hygieneRegimeFromInsight: {
        xray: null,
        exam: null,
        hygiene: null,
    },
    adjunctiveFromInsight: {
        adjunctive: null,
        adjunctiveCodes: null,
    },
    referralsFromInsight: null,
    stagingGradingFromKeyFinding: null,
    stagingGradingData: {
        staging: null,
        grading: null
    },
    extentData: {extent: null},
    hygieneRegimeNotes: null,
    updateXrayCycleLoader: false,
    updateXrayCycleData: null,
    updateXrayCycleError: null
};

const recommendationsTreatmentReducer = (state = initialState, action: any) => {
    switch (action?.type) {
        case GET_TREATMENT_DATA_REQUEST:
            return {
                ...state,
                getRecommendationLoader: true,
                insightRecommendationData: null,
            };
        case GET_TREATMENT_DATA_SUCCESS:
            const updateAdditional = action?.payload?.additional_treatments?.map((res: any) => {
                return {
                    ...res,
                    treatment_details_id_local: res?.ID
                }
            })
            return {
                ...state,
                hygieneRegimeData: action?.payload?.hygiene_regime,
                treatmentData: action?.payload?.treatments,
                scriptData: action?.payload?.scripts,
                insightRecommendationData: action?.payload?.recommendations,
                additionalTreatments: updateAdditional,
                getRecommendationLoader: false,
                referrals: action?.payload?.referrals,
            };
        case GET_TREATMENT_DATA_FAIL:
            return {
                ...state,
                hygieneRegimeData: null,
                treatmentData: null,
                insightRecommendationData: null,
                additionalTreatments: null,
                getRecommendationLoader: false
            };
        case UPDATE_TREATMENT_DATA_REQUEST:
            return {
                ...state,
                pendingRecommendation: true
            };
        case UPDATE_TREATMENT_DATA_SUCCESS:

            return {
                ...state,
                pendingRecommendation: false,
                hygieneRegimeData: action?.payload?.hygiene_regime,
                treatmentData: action?.payload?.treatments,
                insightRecommendationData: action?.payload?.recommendations,
                additionalTreatments: action?.payload?.additional_treatments,
                error: null
            };
        case UPDATE_TREATMENT_DATA_FAIL:
            return {
                ...state,
                pendingRecommendation: false,
            };
        case DELETE_TREATMENT_DATA_REQUEST:
            return {
                ...state,
                deleteRecommendationLoader: true
            };
        case DELETE_TREATMENT_DATA_SUCCESS:
            return {
                ...state,
                deleteRecommendationLoader: false,
                error: null
            };
        case DELETE_TREATMENT_DATA_FAIL:
            return {
                ...state,
                deleteRecommendationLoader: false,
                error: action?.payload?.error
            };
        case TREATMENT_DATA_CHANGE_REQUEST:
            return {
                ...state,
                treatmentData: action?.payload,
            };
        case ADDITIONAL_TREATMENT_DATA_CHANGE_REQUEST:
            return {
                ...state,
                additionalTreatments: action?.payload,
            };
        case RECOMMENDATION_DATA_CHANGE_REQUEST:
            return {
                ...state,
                insightRecommendationData: [...action?.payload],
            };
        case CHECKED_RECOMMENDATION_REQUEST:
            return {
                ...state,
                insightRecommendation: action.payload
            };
        case OPEN_RECOMMENDATION_LOADING_SCREEN:
            return {
                ...state,
                openRecommendationLoadingScreen: action.payload,
            };
        case RECOMMENDATION_LOADER_SCREEN_TIMING_STATE:
            return {
                ...state,
                recommendationLoadingScreenTiming: action.payload,
            };
        case GENERATE_RECOMMENDATION_REQUEST:
            return {
                ...state,
                generateRecommendationLoader: true
            };
        case GENERATE_RECOMMENDATION_REQUEST_SUCCESS:
            return {
                ...state,
                generateRecommendationLoader: false,
                generateRecommendation: action.payload
            };
        case GENERATE_RECOMMENDATION_REQUEST_FAILURE:
            return {
                ...state,
                generateRecommendationLoader: false,
                generateRecommendation: null
            };
        case IS_EDIT_RECOMMENDATION:
            return {
                ...state,
                isEditRecommendation: action?.payload
            }
        case RECOMMENDATION_PREVIOUS_DATA:
            return {
                ...state,
                insightRecommendationPrevious: action.payload?.insightRecommendationPrevious,
                insightRecommendationDataPrevious: action?.payload?.insightRecommendationDataPrevious
            }
        /*Master Api for Get recommendation*/
        case GET_RECOMMENDATION_DATA_REQUEST:
            return {
                ...state,
                getOraQProposedLoader: true
            };
        case GET_RECOMMENDATION_DATA_SUCCESS:
            return {
                ...state,
                getOraQProposedLoader: false,
                oraQProposedData: action?.payload,
                oraQProposedError: null
            };
        case GET_RECOMMENDATION_DATA_FAIL:
            return {
                ...state,
                getOraQProposedLoader: false,
                oraQProposedData: null,
                oraQProposedError: action?.payload?.error,
            };
        case UPDATE_RECOMMENDATION_DATA_REQUEST:
            return {
                ...state,
                oraQProposedData: action?.payload,
            };
        case HYGIENE_REGIME_DATA_CHANGE_REQUEST:
            return {
                ...state,
                hygieneRegimeData: action?.payload,
            };
        case FILTER_PROPOSED_DATA_REQUEST:
            return {
                ...state,
                filterProposedData: action?.payload,
            };
        case ADDITIONAL_ODONTOGRAM_DATA_REQUEST:
            return {
                ...state,
                additionalOdontogramData: action?.payload,
            };
        case ADD_HYGIENE_REGIME:
            return {
                ...state,
                hygieneRegimeFromInsight: action?.payload,
            };
        case ADD_ADJUNCTIVE:
            return {
                ...state,
                adjunctiveFromInsight: action?.payload,
            };
        case SET_STAGING_GRADING_FROM_KEY_FINDING:
            return {
                ...state,
                stagingGradingFromKeyFinding: action.payload
            }
        case UPDATE_STAGING_GRADING:
            return {
                ...state,
                stagingGradingData: action.payload
            }
        case UPDATE_EXTENT:
            return {
                ...state,
                extentData: action?.payload
            }
        case ADD_NOTES_HYGIENE_REGIME:
            return {
                ...state,
                hygieneRegimeNotes: action?.payload
            }
        case ADD_REFERRALS:
            return {
                ...state,
                referralsFromInsight: action?.payload
            }
        case UPDATE_X_RAY_CYCLE_REQUEST:
            return {
                ...state,
                updateXrayCycleLoader: true,
                updateXrayCycleData: null,
                updateXrayCycleError: null
            }
        case UPDATE_X_RAY_CYCLE_REQUEST_SUCCESS:
            return {
                ...state,
                updateXrayCycleLoader: false,
                updateXrayCycleData: action.payload,
                updateXrayCycleError: null
            }
        case UPDATE_X_RAY_CYCLE_REQUEST_FAILURE:
            return {
                ...state,
                updateXrayCycleLoader: false,
                updateXrayCycleData: null,
                updateXrayCycleError: action.payload
            }
        default:
            return {
                ...state
            };
    }
};

export default recommendationsTreatmentReducer;
